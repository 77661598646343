<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div
      v-if="userData.searchType === 1 && !isEmpty(userData.searchType)"
      class="small"
    >
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="countDisplayswitch"
          flat
          :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay"
        ></v-switch>
      </div>
      <br>
    </div>
    <br>
    <div
      class="report-table"
    >
      <v-simple-table
        v-if="!isEmpty(userData.searchType)"
        dense
        class="mytable"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-if="userData.searchType === 1"
                rowspan="2"
                class="text-center"
              >
                Time
              </th>
              <th
                v-if="userData.searchType === 2"
                rowspan="2"
                class="text-center"
              >
                Date
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                class="text-center"
              >
                People
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                class="text-center"
              >
                Car
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                class="text-center"
              >
                Motorbike
              </th>
            </tr>
            <tr>
              <th
                class="text-center"
                style="padding: 0 6px;"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Date
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Week
              </th>
              <th
                class="text-center"
                style="padding: 0 6px;"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Date
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Week
              </th>
              <th
                class="text-center"
                style="padding: 0 6px;"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Cum Total
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Date
              </th>
              <th
                v-if="userData.searchType === 2"
                class="text-center"
                style="background-color:#ffff88 !important;padding: 0 6px;"
              >
                Same Week
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in userData.parkingData"
              :key="item.time"
              :class="getClass(item.time)"
            >
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-right">
                {{ item.peopleNumber }}
              </td>
              <td class="text-right">
                {{ item.peopleCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameDateClass(index)"
              >
                {{ item.peopleLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameWeekClass(index)"
              >
                {{ item.peopleLastYearSameWeekNumber }}
              </td>
              <td class="text-right">
                {{ item.carNumber }}
              </td>
              <td class="text-right">
                {{ item.carCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameDateClass(index)"
              >
                {{ item.carLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameWeekClass(index)"
              >
                {{ item.carLastYearSameWeekNumber }}
              </td>
              <td class="text-right">
                {{ item.bikeNumber }}
              </td>
              <td class="text-right">
                {{ item.bikeCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameDateClass(index)"
              >
                {{ item.bikeLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                :class="getLastYearSameWeekClass(index)"
              >
                {{ item.bikeLastYearSameWeekNumber }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-for="item in userData.parkingFooterData"
              :key="item.time"
              class="yellow"
            >
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-right">
                {{ item.peopleNumber }}
              </td>
              <td class="text-right">
                {{ item.peopleCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.peopleLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.peopleLastYearSameWeekNumber }}
              </td>
              <td class="text-right">
                {{ item.carNumber }}
              </td>
              <td class="text-right">
                {{ item.carCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.carLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.carLastYearSameWeekNumber }}
              </td>
              <td class="text-right">
                {{ item.bikeNumber }}
              </td>
              <td class="text-right">
                {{ item.bikeCumulativeTotal }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.bikeLastYearSameDateNumber }}
              </td>
              <td
                v-if="userData.searchType === 2"
                class="text-right"
              >
                {{ item.bikeLastYearSameWeekNumber }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <!-- excel出力用 -->
    <div
      id="mainTable"
      hidden
      :data-cols-width="userData.searchType === 1 ? '10,15,15,15,15,15,15':'20,20,20,20,20,20,20,20,20,20,20,20,20'"
    >
      <table><tr></tr></table>
      <v-simple-table
        v-if="!isEmpty(userData.searchType)"
        dense
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-if="userData.searchType === 1"
                data-a-h="center"
                data-b-a-s="thin"
                rowspan="2"
              >
                Time
              </th>
              <th
                v-else
                data-a-h="center"
                data-b-a-s="thin"
                rowspan="2"
              >
                Date
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                data-a-h="center"
                data-b-a-s="thin"
              >
                People
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                data-a-h="center"
                data-b-a-s="thin"
              >
                Car
              </th>
              <th
                :colspan="userData.searchType === 1 ? '2':'4'"
                data-a-h="center"
                data-b-a-s="thin"
              >
                Motorbike
              </th>
            </tr>
            <tr>
              <th
                data-a-h="center"
                data-b-a-s="thin"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Date)
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Week)
              </th>
              <th
                data-a-h="center"
                data-b-a-s="thin"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Date)
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Week)
              </th>
              <th
                data-a-h="center"
                data-b-a-s="thin"
              >
                Number
              </th>
              <th
                v-if="userData.searchType === 1"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                Cumulative Total
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Date)
              </th>
              <th
                v-if="userData.searchType === 2"
                data-a-h="center"
                data-b-a-s="thin"
                data-fill-color="ffff88"
              >
                LastYear (Same Week)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in userData.parkingData"
              :key="item.time"
            >
              <td
                v-if="userData.searchType === 1"
                :data-fill-color="getColor(item.time, index)"
                data-a-h="center"
                data-b-a-s="thin"
              >
                {{ item.time }}
              </td>
              <td
                v-else
                data-b-a-s="thin"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ item.time }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleNumber === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.peopleNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleCumulativeTotal === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.peopleCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleLastYearSameDateNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameDataColor(index)"
              >
                {{ String(item.peopleLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleLastYearSameWeekNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameWeekColor(index)"
              >
                {{ String(item.peopleLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carNumber === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.carNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carCumulativeTotal === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.carCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carLastYearSameDateNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameDataColor(index)"
              >
                {{ String(item.carLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carLastYearSameWeekNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameWeekColor(index)"
              >
                {{ String(item.carLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeNumber === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.bikeNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeCumulativeTotal === '0' ? '0':'#,###'"
                :data-fill-color="getColor(item.time, index)"
              >
                {{ String(item.bikeCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeLastYearSameDateNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameDataColor(index)"
              >
                {{ String(item.bikeLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeLastYearSameWeekNumber === '0' ? '0':'#,###'"
                :data-fill-color="getSameWeekColor(index)"
              >
                {{ String(item.bikeLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-for="item in userData.parkingFooterData"
              :key="item.time"
            >
              <td
                data-fill-color="ffff88"
                data-a-h="center"
                data-b-a-s="thin"
              >
                {{ item.time }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.peopleNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleCumulativeTotal === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.peopleCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleLastYearSameDateNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.peopleLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.peopleLastYearSameWeekNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.peopleLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.carNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carCumulativeTotal === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.carCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carLastYearSameDateNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.carLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.carLastYearSameWeekNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.carLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.bikeNumber).replace(',','').replace(',','') }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeCumulativeTotal === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.bikeCumulativeTotal).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeLastYearSameDateNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.bikeLastYearSameDateNumber).replace(',','').replace(',','') }}
              </td>
              <td
                v-if="userData.searchType === 2"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.bikeLastYearSameWeekNumber === '0' ? '0':'#,###'"
                data-fill-color="ffff88"
              >
                {{ String(item.bikeLastYearSameWeekNumber).replace(',','').replace(',','') }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <div>
      <br>
      <v-btn
        v-if="!loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </div>
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import 'echarts/lib/chart/line'
import parkingStoreModule from './parkingStoreModule'
import tableToExcelJs from '@linways/table-to-excel'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-parking'

const userData = ref([])
const echarts = require('echarts')

export default {
  components: { Loading },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        if (val.searchType === 1) {
          this.drawLine(val)
        }
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      this.loading = true
      store
        .dispatch('app-parking/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: val.labelShow,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '3%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      console.log(JSON.stringify(lineChartOption))

      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },

    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    getLastYearSameDateClass(index) {
      let css = 'Normal text-right'
      const time = this.userData.lastLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getLastYearSameWeekClass(index) {
      let css = 'Normal text-right'
      const time = this.userData.lastYearSameWeekData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getClass(time) {
      let css = ''

      if (this.userData.searchType === 1) {
        return css
      }

      if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },

    getColor(time, index) {
      let color = ''
      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          color = ''
        } else {
          color = 'c0c0c0'
        }
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else {
        color = ''
      }

      return color
    },

    getSameDataColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      }

      return color
    },
    getSameWeekColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastYearSameWeekData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      }

      return color
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, parkingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const countDisplayswitch = ref(true)

    return {
      countDisplayswitch,
      isEmpty,
      userData,
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      loading: false,
    }
  },
}
</script>

<style scoped>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.mytable th,
td {
  width: 150px;
}
.mytable2 th {
  white-space: nowrap;
}
.mytable2 th,
td {
  width: 90px;
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}
.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Normal {
  background-color: #fff;
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 1px solid rgba(94, 86, 105, 0.14);
}
.theme--light.v-data-table th {
  border: 1px solid rgba(94, 86, 105, 0.14);
}
</style>
